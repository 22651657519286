import React, { useEffect, useState } from "react";
import { fetchApiGet, fetchApiPost } from "../utils/FetchApi";
import { ApiName } from "../utils/ApiName";
import tagManagerEvents from "../utils/GoogleTagManager";
import Awareness from "../pages/Awareness";

type Props = {
  token: string | null;
};

let adNetwork = "";
const AwarenessScreen = (props: Props) => {
  const [isHidden, setIsHidden] = useState(true);

  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const utmSource: any = searchParams.get("utm_source");
  const qsPackageId = searchParams.get("package_id")
    ? searchParams.get("package_id")
    : "1";
  useEffect(() => {
    if (props.token) {
      checkUtm(utmSource);
      getFlow(utmSource);
    }
  }, [props.token]);

  const checkUtm = async (utmSource: string) => {
    try {
      let response = await fetchApiGet(props.token, ApiName.utmList);
      console.log("UTM response", response);
      if (response.status === 0) {
        {
          response.response.map((e: any) => {
            if (utmSource === e.sf_utm) {
              adNetwork = e.ad_network;
            }
          });
          console.log("ad_network", adNetwork);
        }
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  const getFlow = async (utmSource: string) => {
    try {
      let _qsPackageId = "1";
      if (qsPackageId === "1" || qsPackageId === "2" || qsPackageId === "3") {
        _qsPackageId = qsPackageId;
      }
      let data = {
        utm_source: utmSource,
        package_id: _qsPackageId,
      };
      let response = await fetchApiPost(
        props.token,
        ApiName.awarenessFlow,
        data
      );
      if (response.status === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          console.warn("aaa");
          if (qsPackageId === "1") {
            tagManagerEvents("subscribe", "tiktok");
          } else if (qsPackageId === "2") {
            tagManagerEvents("subscribe", "tiktokWeekly");
          } else if (qsPackageId === "3") {
            tagManagerEvents("subscribe", "tiktokMonthly");
          } else {
            tagManagerEvents("subscribe", utmSource);
          }
        } else if (adNetwork.toLowerCase() === "google") {
          console.warn("bbb");
          tagManagerEvents("subscribe", "optimus");
        } else {
          console.warn("ccc");
          tagManagerEvents("subscribe", utmSource);
        }
        setIsHidden(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOverLay = () => {
    setIsHidden(true);
  };

  return <Awareness isHidden={isHidden} handleOverLay={handleOverLay} />;
};

export default AwarenessScreen;
