import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="jazz_discount_outer">
      <h2>TAMASHA PRIVACY NOTICE</h2>
      <div
        style={{
          background: "rgba(0,0,0,0.8)",
          padding: "20px",
          color: "#fff",
        }}
      >
        <div className="How-to-Play">
          <p>
            Tamasha is a leading mobile application serving as an Over-The-Top
            (OTT) platform, providing an immersive entertainment experience
            through a wide range of live streaming channels and Video On Demand
            (VOD) content. These channels include your favorite movies, dramas,
            live sports events such as cricket tournaments, news updates and a
            variety of entertainment TV channels - all accessible on your mobile
            device.
          </p>
          <strong>Scope and Application</strong>
          <p className="mt-3">
            We prioritize your privacy and handle your personal information with
            the utmost care, in compliance with applicable laws of Pakistan. Our
            privacy policy outlines how we transparently collect, store, use,
            and disclose your personal information. Please read this notice
            along with the Jazz Customer Privacy Notice at{" "}
            <a
              href="https://jazz.com.pk/help/customer-privacy-policy/"
              target="_blank"
            >
              https://jazz.com.pk/help/customer-privacy-policy/
            </a>{" "}
            to fully understand the measures taken to safeguard your privacy.
          </p>
          <strong>Information We Collect About You</strong>
          <p className="mt-3">
            Through our mobile application (Tamasha App) and website (Tamasha
            Web), we collect personal information, including but not limited to
            your name, mobile number, email address, physical address –
            optional, technical data (such as location, usage, preferences,
            Bluetooth permission, notification permissions, picture uploads –
            optional, device information, browser information, active time,
            service usage, interface usage habits, error information,
            subscription data, device model, operating system and operator
            information).
          </p>
          <strong>How We Collect your information</strong>
          <p className="mt-3">We collect and process data when you:</p>
          <ul>
            <li className="mb-0">
              Register online, through our mobile application (Tamasha App) and
              website (Tamasha Web), or place an order for any of our services.
            </li>
            <li className="mb-0">
              Voluntarily complete a customer survey or provide feedback on any
              of our message board or via email.
            </li>
            <li className="mb-0">
              Use or view our website via your browser's cookies.
            </li>
          </ul>
          <strong>How We Use Your Information</strong>
          <p className="mt-3">
            We use this data to facilitate subscriptions and deliver a
            personalized app experience, which involves analyzing preferences
            and usage patterns. This includes customer profiling, providing
            customer support, selecting and announcing winners in incentivized
            campaigns, managing subscriptions, and processing un-subscriptions.
            Additionally, location data may be collected for geo-fencing
            purposes, as this service is exclusive to Pakistan.
          </p>
          <p>
            We collect your data so that we can process the same, and email/
            notify you with any special offers on services we think you might
            like.
          </p>
          <strong>Usage and Safety of Your Information</strong>
          <p className="mt-3">
            Security is our top priority. We employ various technical and
            organizational measures such as encryption, access controls,
            firewalls, and regular security assessments to protect your data
            from unauthorized access, disclosure, alteration, or destruction.
          </p>
          <strong>Marketing</strong>
          <p className="mt-3">
            We would like to send you information about products and services of
            ours that we think you might like, as well as those of our partner
            companies.
          </p>
          <p>
            For details on opting out of marketing materials and analytics,
            kindly refer to the Jazz Privacy Notice available at the following
            link:{" "}
            <a
              href="https://jazz.com.pk/help/customer-privacy-policy/"
              target="_blank"
            >
              https://jazz.com.pk/help/customer-privacy-policy/
            </a>
            .
          </p>
          <strong>Sharing Your Information</strong>
          <p className="mt-3">
            We rely on a trusted third-party service to handle essential
            functions, ensuring the secure management of user data, library and
            metadata. Simultaneously, our authentication process protects your
            information and enables effective user management. You can enjoy
            real-time updates for instant content additions and seamless user
            interactions, with media files stored and delivered securely. Our
            use of analytics helps us gain insights into user behavior, and
            continuous performance monitoring guarantees a smooth app
            experience.
          </p>
          <p>
            Rest assured that your data security and app performance are our
            utmost priorities. In certain instances, we may also need to share
            Personal Data to provide specific services, taking into account your
            product or service usage, and strictly adhering to legal,
            regulatory, and security requirements with rigorous authorization
            and privacy measures. Your trust in us matters, and we're dedicated
            to safeguarding your privacy and ensuring a top-notch app
            experience.
          </p>
          <strong>Data Retention</strong>
          <p className="mt-3">
            We retain personal data for three months, ensuring compliance with
            applicable data retention laws. We prioritize your privacy while
            adhering to regulatory obligations.
          </p>
          <strong>Cookies and Tracking Technologies</strong>
          <p className="mt-3">
            We use cookies on our website and advertiser IDs in the Tamasha
            mobile application for tracking. Users agree to the terms and
            conditions and consent to the use of their data, provided it is not
            shared outside of affiliated group companies.
          </p>
          <strong>Handling Data Breaches</strong>
          <p className="mt-3">
            We have automated application alerts and comprehensive data security
            policies in place for a prompt response to any breaches.
          </p>
          <strong>Consent and Opt-Out of Data Processing</strong>
          <p className="mt-3">
            You can choose to opt out of our platform in several ways: by
            unsubscribing through the Tamasha App or Jazz World App, or by
            contacting our Call Centers. You can also choose to uninstall the
            Tamasha App and your data will be purged as per our data retention
            policy. Moreover, you have the convenience of managing notifications
            directly in your device settings. Should you seek further insight or
            clarification about our privacy practices and data retention policy,
            please feel free to reach out to us. Rest assured, your privacy
            holds the highest priority in our commitment to providing you with a
            trusted entertainment experience through Tamasha and we deeply
            appreciate the trust you've placed in us.
          </p>
          <strong>Changes to the Privacy Notice</strong>
          <p className="mt-3">
            We understand the importance of keeping you informed about changes
            to our privacy notice. The updated notice is published via the
            Application and significant changes are notified through in-app
            notifications. Please review the Policy section regularly, as it may
            vary depending on usage and requests. We remain committed to
            ensuring your privacy and security in all updates.
          </p>
          <strong>How to contact us</strong>
          <p className="mt-3">
            If you have any questions about our privacy policy, please do not
            hesitate to contact us.
          </p>
          <p className="mt-2">Email us at: customercare@beyonddigital.com.pk</p>
          <p>
            Call us on 111 for Prepaid and 777 for postpaid or message us on
            Whatsapp at: 0300-311-1119 or Use our Jazz World App.
          </p>
          <p className="mt-2">
            For inquiries about the handling of your personal data—including
            collection, usage, disclosure, transfer, processing—or to exercise
            your rights, or if you have a complaint or feel your concerns
            haven't been adequately resolved, please reach out to the Data
            Protection Officer at dpo@jazz.com.pk.
          </p>
          <p className="mt-6">
            <strong>Effective Date: 13/11/2023</strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
