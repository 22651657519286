import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import JApp from "./JApp";
import "./DisableLogs";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    {window.location.pathname.toLowerCase().includes("/zongtamasha") ? (
      <App />
    ) : (
      <JApp />
    )}
  </BrowserRouter>
  // </React.StrictMode>
);
reportWebVitals();
