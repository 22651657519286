import React, { useEffect, useState } from "react";
import LandingJazzCash from "../pages/LandingJazzCash";
import { fetchApiGet, fetchApiPost } from "../utils/FetchApi";
import { ApiName } from "../utils/ApiName";

type Props = {
  token: string | null;
};

const LandingJazzCashScreen = (props: Props) => {
  const [phoneNo, setPhoneNo] = useState("");
  const [error, setError] = useState("");
  const [isCheckRadioBtn, setIsCheckRadioBtn] = useState(true);

  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const utmSource: any = searchParams.get("utm_source");

  useEffect(() => {
    if (props.token) {
      getFlow();
    }
  }, [props.token]);

  const getFlow = async () => {
    try {
      let data = {
        utm_source: utmSource,
      };
      let response = await fetchApiPost(props.token, ApiName.getFlow, data);
      if (response.status === 0) {
        let phoneNumber = response.response.msisdn;
        setPhoneNo(phoneNumber.slice(2));
      }
    } catch (error) {
      console.log("handleGetFlow Error: ", error);
    }
  };

  const handlePhoneNoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (!value || (value.length <= 10 && value.match(/^3\d{0,9}$/))) {
      setError("");
      setPhoneNo(e.target.value);
    }
  };

  const handleNavigation = () => {
    if (phoneNo.length === 10 && isCheckRadioBtn) {
      window.open(
        `http://subscribe.tamashaweb.com/api/link-wallet-initialize?msisdn=${phoneNo}&package_id=3`,
        "_blank",
        "noopener,noreferrer"
      );
    } else if (phoneNo.length != 10) {
      setError("Please enter a valid 10-digit number");
    } else {
      setError("Please agree to the Terms of Use and Privacy Policy");
    }
  };

  return (
    <>
      <LandingJazzCash
        phoneNo={phoneNo}
        handlePhoneNoChange={handlePhoneNoChange}
        error={error}
        handleNavigation={handleNavigation}
        isCheckRadioBtn={isCheckRadioBtn}
        setIsCheckRadioBtn={setIsCheckRadioBtn}
        setError={setError}
      />
    </>
  );
};

export default LandingJazzCashScreen;
