import React, { useEffect, useState } from "react";
import tagManagerEvents from "../utils/GoogleTagManager";
import { Navigate, useLocation } from "react-router-dom";
import { fetchApiGet, fetchApiPost } from "../utils/FetchApi";
import { ApiName } from "../utils/ApiName";
import LandingPage from "../pages/LandingPage";

type Props = {
  token: string | null;
};

let adNetwork = "";
const LandingScreen = (props: Props) => {
  const [phoneNo, setPhoneNo] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showError, setShowError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [validNum, setValidNum] = useState(true);
  const [timer, setTimer] = useState(30);
  const [seconds, setSeconds] = useState(30);
  const [isResendClicked, setResendClicked] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [otp, setOtp] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
  });

  const [inputRef, setInputRef] = useState<React.RefObject<HTMLInputElement>[]>(
    [
      React.createRef<HTMLInputElement>(),
      React.createRef<HTMLInputElement>(),
      React.createRef<HTMLInputElement>(),
      React.createRef<HTMLInputElement>(),
    ]
  );

  const { state } = useLocation();

  useEffect(() => {
    if (isResendClicked) {
      setSeconds(30);
      setResendClicked(false);
    }

    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(interval);
        }
        return prevSeconds > 0 ? prevSeconds - 1 : 0;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [isResendClicked]);

  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const utmSource: any = searchParams.get("utm_source");

  const qsPackageId = searchParams.get("package_id")
    ? searchParams.get("package_id")
    : "1";

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (timer > 0) {
      interval = setTimeout(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearTimeout(interval);
  }, [timer]);

  useEffect(() => {
    if (state?.jc === 1) {
      setIsHidden(false);
    } else if (state?.jc === 0) {
      openPopup();
    } else {
      closePopup();
    }

    if (props.token) {
      getFlow();
      checkUtm();
    }
  }, [props.token]);

  const getFlow = async () => {
    try {
      let data = {
        utm_source: utmSource,
        // msisdn: msisdn,
      };
      let response = await fetchApiPost(props.token, ApiName.getFlow, data);
      if (response.status === 0) {
        if (
          response.response.isSpecialFlow === true &&
          response.response.msisdn !== "" &&
          response.response.otp !== ""
        ) {
          let phoneNumber = response.response.msisdn;
          let _qsPackageId = "1";
          if (
            qsPackageId === "1" ||
            qsPackageId === "2" ||
            qsPackageId === "3"
          ) {
            _qsPackageId = qsPackageId;
          }
          let data_ = {
            msisdn: phoneNumber.slice(2),
            otp: response.response.otp,
            package_id: _qsPackageId,
            utm_source: utmSource,
          };

          let Api_name = response.response.fastFlow
            ? ApiName.ffSubscribe
            : ApiName.subscribe;

          subscribeUser(Api_name, data_);
        } else if (
          response.response.isSpecialFlow === false &&
          response.response.msisdn
        ) {
          // 4g user without special flow
          let phoneNumber = response.response.msisdn;
          setPhoneNo(phoneNumber.slice(2));
          console.log("true kr da bhai");
          sendOTP(phoneNumber.slice(2), true);

          tagManagerEvents("heUser", utmSource);
          if (response.response.signIn) {
            setIsHidden(false);
          }
        } else {
          // wifi user
          tagManagerEvents("wifiUser", utmSource);
        }
      } else {
        setShowError(true);
        setErrorMsg("Something went wrong");
      }
    } catch (error) {
      setShowError(true);
      setErrorMsg("Something went wrong");
      console.log("handleGetFlow Error: ", error);
    }
  };

  const handlePhoneNoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (!value || (value.length <= 10 && value.match(/^3\d{0,9}$/))) {
      setShowError(false);
      setValidNum(true);
      setPhoneNo(e.target.value);
      setOtp({
        one: "",
        two: "",
        three: "",
        four: "",
      });
      if (value.length === 10) {
        checkOperator(e.target.value);
      }
    } else {
    }
  };

  const checkOperator = async (phoneNumber: string) => {
    try {
      let response = await fetchApiGet(
        props.token,
        ApiName.checkOperator + "?msisdn=" + phoneNumber
      );
      if (
        response.status === 0 &&
        response.network.toLowerCase() === "other network"
      ) {
        setValidNum(false);
        setShowError(true);
        setErrorMsg(
          "Service is only for Jazz customers. Kindly enter a working Jazz number."
        );
      } else {
        sendOTP(phoneNumber, false);
      }
    } catch (err) {
      setValidNum(false);
      setShowError(true);
      setErrorMsg("Something went wrong, Please try again.");
    }
  };

  const sendOTP = async (phoneNo: string, autoFill: boolean) => {
    let data = {
      msisdn: phoneNo,
    };
    try {
      let response = await fetchApiPost(props.token, ApiName.sendOtp, data);
      if (response.status === 0) {
        if (autoFill) {
          let _otp = response.response.split("");
          let tempOTP = { ...otp };
          tempOTP.one = _otp[0];
          tempOTP.two = _otp[1];
          tempOTP.three = _otp[2];
          tempOTP.four = _otp[3];
          console.log(tempOTP);
          setOtp(tempOTP);
        }
      } else {
        setShowError(true);

        setErrorMsg("Unable to send OTP, Please try again.");
      }
    } catch (err) {
      setShowError(true);

      setErrorMsg("Something went wrong, Please try again.");
    }
  };

  const onSubscribePressed = () => {
    let checkOTP = otp.one + otp.two + otp.three + otp.four;
    let _qsPackageId = "1";
    if (qsPackageId === "1" || qsPackageId === "2" || qsPackageId === "3") {
      _qsPackageId = qsPackageId;
    }
    let data = {
      msisdn: phoneNo,
      otp: checkOTP,
      package_id: _qsPackageId,
      utm_source: utmSource,
    };
    subscribeUser(ApiName.subscribe, data);
  };
  //Utm
  const checkUtm = async () => {
    try {
      let response = await fetchApiGet(props.token, ApiName.utmList);
      if (response.status === 0) {
        {
          response.response.map((e: any) => {
            if (utmSource === e.sf_utm) {
              console.log("ad_network", e.ad_network);
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  // --------------- APIs ---------------
  const subscribeUser = async (endpoint: string, data: any) => {
    try {
      setShowLoader(true);
      let response = await fetchApiPost(props.token, endpoint, data);
      if (response.code === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          console.warn("aaa");
          if (qsPackageId === "1") {
            tagManagerEvents("subscribe", "tiktok");
          } else if (qsPackageId === "2") {
            tagManagerEvents("subscribe", "tiktokWeekly");
          } else if (qsPackageId === "3") {
            tagManagerEvents("subscribe", "tiktokMonthly");
          } else {
            tagManagerEvents("subscribe", utmSource);
          }
        } else if (adNetwork.toLowerCase() === "google") {
          console.warn("bbb");
          tagManagerEvents("subscribe", "optimus");
        } else {
          console.warn("ccc");
          tagManagerEvents("subscribe", utmSource);
        }
        setIsHidden(false);
        setShowLoader(false);
      } else {
        tagManagerEvents("subscribeFailed", utmSource);
        setShowLoader(false);
        setShowError(true);
        setErrorMsg("Unable to subscribe,Please try again.");
      }
    } catch (error) {
      tagManagerEvents("subscribeFailed", utmSource);
      setShowLoader(false);
      setShowError(true);
      setErrorMsg("Unable to subscribe,Please try again.");
    }
  };

  const handleChangeOTP = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let otpEntered = e.target.value;
    setShowError(false);

    if (!isNaN(otpEntered as any) && otpEntered !== " ") {
      setOtp({
        ...otp,
        [e.target.name]: otpEntered,
      });
      if (e.target.value.length === 1) {
        const nextInput = inputRef[index + 1];
        if (nextInput?.current) {
          nextInput.current.focus();
        }
      }
    } else {
      setShowError(true);
      setErrorMsg("Please enter a valid OTP");
    }
  };
  // backspace button
  const handleBackspace = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ): void => {
    if (e.keyCode === 8) {
      setShowError(false);
      const target = e.target as HTMLInputElement;
      if (target.value.length === 0 && index !== 0) {
        (inputRef[index - 1].current as HTMLInputElement).focus();
      }
    }
  };

  const onResendOtpPressed = async () => {
    tagManagerEvents("resendOTP", utmSource);
    setTimer(30);
    setResendClicked(true);
    setOtp({
      one: "",
      two: "",
      three: "",
      four: "",
    });
    sendOTP(phoneNo, false);
  };

  const handleOverLay = () => {
    setIsHidden(true);
  };

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const openPopup = () => {
    setIsPopupVisible(true);
  };
  // Function to close the popup
  const closePopup = () => {
    setIsPopupVisible(false);
  };

  return (
    <LandingPage
      showLoader={showLoader}
      errorMsg={errorMsg}
      showError={showError}
      onSubscribePressed={onSubscribePressed}
      phoneNo={phoneNo}
      otp={otp}
      handleChangeOTP={handleChangeOTP}
      handleBackspace={handleBackspace}
      onResendOtpPressed={onResendOtpPressed}
      inputRef={inputRef}
      handlePhoneNoChange={handlePhoneNoChange}
      seconds={seconds}
      validNum={validNum}
      isHidden={isHidden}
      handleOverLay={handleOverLay}
      package_id={qsPackageId}
      isPopupVisible={isPopupVisible}
      openPopup={openPopup}
      closePopup={closePopup}
    />
  );
};

export default LandingScreen;
