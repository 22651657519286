import "../styles/Terms.css";

function Terms() {
  return (
    <div className="Zong_discount_outer">
      <h2>Terms and conditions for “Tamasha Connect”</h2>
      <div
        style={{
          background: "rgba(0,0,0,0.8)",
          padding: "20px",
          color: "#fff",
        }}
      >
        <div>
          <p>
            <strong>"Tamasha Connect"</strong> is a promotional activity for
            customer engagement by Beyond Digital (Private) Limited (“BDPL”). It
            is a timebound activity run during promotional period from 1st Feb
            2024 to 31st March 2024 (<strong>"Promotional Period"</strong>) and
            is designed to engage with and motivate the customers to subscribe
            Tamasha App on daily basis, remain connected with Tamasha App and
            enjoy the experience through viewership of the content available on
            Tamasha App throughout the Promotional Period.
          </p>
          <p>
            The customers who choose to participate in the Tamash Connect
            activity will be eligible for winning lucrative incentive(s)/prize
            (s) from BDPL. By entering the Tamasha Connect activity, you agree
            to be bound by these terms and conditions. If you do not wish to
            accept these terms and conditions, please do not enter the Tamash
            Connect activity.
          </p>
          <strong>Eligibility and How to Participate</strong>
          <ul className="mt-2">
            <li className="mt-3">
              Users of Tamasha App from all networks in Pakistan (i.e., Mobile
              and Fixed) can participate in this Promotional activity and can
              win incentives. Normal daily subscription charges will apply.
              Employees of BDPL, Zong and entities or third parties, if any,
              sponsoring the prizes/incentives will not be eligible for
              participation and incentives.
            </li>
            <li>
              The Tamasha Connect activity will start on 1st February, 2024 @
              00.01 hours and will end on 31st March 2024 @ 23:59 hours.
            </li>
            <li>
              The decision of the winner will be announced by 5th April, 2024 @
              18:00 hours.
            </li>
            <li>
              Entry to this Tamasha Connect is limited to the region of
              Pakistan.
            </li>
          </ul>
          <strong>Selection Criteria</strong>
          <ul>
            <li className="mt-3">
              The winner of the Tamasha Connect will be selected based on the
              following criteria:
            </li>
            <ol className="lower-alpha" type="a">
              <li>
                The participant with the maximum days of subscription to the
                daily charging plan during the Promotion Period will be selected
                as the winner; and/alternatively
              </li>
              <li>
                In the event of a tie{" "}
                <i>
                  (multiple participants with the same number of subscription
                  days to the daily charging plan during the Promotion Period)
                </i>
                , the winner will be determined based on the participant with{" "}
                <strong>
                  <u>maximum days of subscriptions</u>
                </strong>{" "}
                plus{" "}
                <strong>
                  <u>
                    the most Minutes of Use of Tamasha Platform (App &amp; web)
                  </u>
                </strong>{" "}
                during the Promotion Period.
              </li>
            </ol>
          </ul>
          <strong>Incentives/Prize</strong>
          <ul>
            <li className="mt-3">
              The Prize/incentive will be - One (01) Samsung A 54 Handset.
            </li>
          </ul>
          <strong>Terms relating to Prize/incentives:</strong>
          <ul>
            <li className="mt-3">
              BDPL will reach out to the winner who must then provide their full
              legal name, nominated prize delivery address, and contact phone
              number within 48 hours. If the winner fails to do so or is unable
              to comply with these terms and conditions, BDPL reserves the right
              to cancel the prize/incentives.
            </li>
            <li>
              If the winner fails to redeem his/her prize within the prescribed
              time, this will be deemed as a waiver of the prize and the prize
              will be cancelled accordingly.
            </li>
            <li>
              BDPL will not be held responsible for rescheduling, replacement,
              or other liabilities.
            </li>
            <li>
              In cases where a prize cannot be delivered due to causes
              originating with the winner{" "}
              <i>
                (including but not limited to failure to provide contact
                information within 48 hours of the conclusion of the event or
                submission of incomplete or inaccurate contact information that
                prevents BDPL from establishing contact)
              </i>{" "}
              the prize will automatically be considered waived. BDPL assumes no
              responsibility for any such outcome and will not reissue the prize
              in such cases. Upon receiving their prize, the winner will be
              expected to confirm completion of delivery by notifying BDPL
              directly, except where abnormal circumstances prevent this. BDPL
              shall not be liable for any accidents or damages suffered by the
              winner relating to the use or receipt of the prize/incentives.
            </li>
            <li>The Prize/Incentive will not be exchanged for cash.</li>
            <li>
              If unforeseen circumstances prevent the distribution of the
              prize/incentive to the winner, BDPL reserves the right to issue
              another prize/incentive of the equivalent price to the winner as
              per its discretion, and the winner will not question this decision
              of BDPL before any forum.
            </li>
            <li>
              The winner shall bear full responsibility for any personal income
              taxes incurred due to winning of the aforesaid Prize/incentive
              (s), and BDPL is not responsible in this regard.
            </li>
            <li>
              Once the Prize/Incentive has been dispatched as per the
              details/information provided by the, BDPL takes no responsibility
              for the Prize if damaged, delayed or lost in transit.
            </li>
          </ul>
          <strong>Support</strong>
          <ul>
            <li className="mt-3">
              This Tamasha Connect is in no way is sponsored, endorsed or
              administered by, or associated with Samsung. Any questions,
              comments, or complaints about Tamasha Connect or Incentive/Prize
              must be directed to BDPL as below:
            </li>
            <p>
              <strong>WhatsApp Support:</strong> 0300-3111119
            </p>
            <p>
              <strong>Email:</strong> customercare@beyonddigital.com.pk
            </p>
          </ul>
          <strong>Changes to the Terms &amp; Conditions</strong>
          <ul>
            <li>
              BDPL reserves the right to amend or changes the terms and
              conditions as per its discretion at any time. Please visit Tamasha
              App and read these terms and conditions regularly to ensure you
              are aware of and understand any changes made by BDPL. You accept
              these terms and conditions as legally binding regardless of any
              changes occurring herein.
            </li>
            <li>
              In the event of unforeseen circumstances beyond BDPL's reasonable
              control, it reserves the right to cancel, terminate, or suspend
              the Tamash Connect activity or modify, alter or change the terms
              and conditions, either in whole or in part, with or without
              notice, as per its discretion.
            </li>
          </ul>
          <strong>Privacy and Personal Information Policy</strong>
          <ul>
            <li className="mt-3">
              Personal information collected from the winner will be used by
              BDPL for the purpose of delivering incentive/prize to the winner.
            </li>
          </ul>
          <strong>Additional Terms &amp; Conditions</strong>
          <ul>
            <li className="mt-3">
              Incentives entitlement will be valid for the participation in
              Tamasha Connect during Promotional Period, i.e., from 1st Feb 2024
              till 31st March 2024 only.
            </li>
            <li>
              Users of Tamasha App from all networks in Pakistan (i.e., Mobile
              and Fixed) can participate in this Promotional activity and can
              win incentives. Employees of BDPL, Zong and entities or third
              parties, if any, sponsoring the prizes/incentives will not be
              eligible for participation and incentives.
            </li>
            <li>
              Normal daily subscription charges @Rs.10 inclusive of taxes will
              apply.
            </li>
            <li>
              Score will be calculated based on maximum days of subscription of
              Tamasha App during the Promotional Period, and in case of tie
              maximum number of days of subscription plus maximum Minutes of use
              of Tamasha Platform (App &amp; Web) during the Promotional Period.
            </li>
            <li>
              The name of the winner shall appear on digital platforms, if any,
              as per discretion of BDPL.
            </li>
            <li>
              BDPL may change any of the terms and conditions of this Tamasha
              Connect activity at any time as per its discretion.
            </li>
            <li>
              Participating of a subscriber of Tamasha App in Tamasha Connect
              shall be considered that the subscriber has:
            </li>
            <ul>
              <li>
                accepted the terms and conditions of this Tamasha Connect
                activity available on Tamasha App with his “free consent”.
              </li>
              <li>
                given his “free consent” for showing his name/credentials on
                social media.
              </li>
            </ul>
            <li>
              The subscriber who is not agreeing to the aforesaid conditions,
              will not be eligible to participate and may quit the Tamasha
              Connect.
            </li>
            <li>
              The subscriber participating in the Tamasha Connect cannot
              question the terms and conditions including any changes in terms
              and conditions nor raise objection on showing his name/credential
              or consent thereafter.
            </li>
          </ul>
          <strong>Last updated: 09-02-24</strong>
        </div>
      </div>
      <h2 style={{marginTop: "20px"}}>Terms & Conditions</h2>
      <div
        style={{
          background: "rgba(0,0,0,0.8)",
          padding: "20px",
          color: "#fff",
        }}
      >
        <strong className="main-heading-tu">Welcome to Tamasha!</strong>
        <p>
          Please read these Tamasha Terms of Use <strong>("Terms")</strong>{" "}
          carefully before using{" "}
          <a
            className="text-blue"
            href="https://tamashaweb.com"
            target="_blank"
          >
            https://tamashaweb.com
          </a>{" "}
          and the Tamasha mobile application (“<strong>Tamasha Platform</strong>
          ”). The Tamasha Platform is owned by Beyond Digital (Private) Limited,
          a company registered under the laws of Pakistan
          <strong> (“BDPL”, “We”, “Us”, “Our”)</strong>. These Terms govern your
          use of the Tamasha Platform.
        </p>
        <p>
          By accepting these Terms in any manner, or accessing or using the
          Tamasha Platform and/or the content, you agree that you have read,
          understood, and expressly consent, agree and undertake to abide, be
          bound by and adhere to these Terms, the privacy notice accessible at{" "}
          <strong>
            <a href="/privacy-policy" target="_blank">
              “Privacy Policy”
            </a>
          </strong>{" "}
          and any other written terms and conditions or policies specified on
          the Tamasha Platform (including terms governing the processing of your
          payments by a third party). If you do not agree to these Terms, the
          Privacy Notice or any other applicable terms or policies, you must not
          use the Tamasha Platform or access the content.
        </p>
        <ol className="main-list mt-2">
          <li>
            <strong>OUR SERVICES</strong>
            <ol className="first-nested-list ml-4 pl-0">
              <li className="mt-3">
                All rights, including intellectual property rights without
                limitation, copyright, database rights and trademark rights in
                the Tamasha Platform and its contents, whether audio, text,
                images, video or any other form ("<strong>Content</strong>"),
                are owned by or licensed to BDPL, or otherwise used by BDPL as
                permitted by applicable law. The Content is protected by
                copyright laws and treaties around the world. All such rights
                are reserved to BDPL and/or our licensors.
              </li>
              <li>
                We give you permission to stream, access and view the Content on
                the Tamasha Platform, provided you comply with these Terms. You
                agree that your permission to stream, access and view the
                Content on the Tamasha Platform is solely for your own private,
                personal and domestic use and not for any commercial, business
                or public use purposes. You agree that you may not copy,
                reproduce, republish, download (except where expressly
                permitted), post, adapt, create a derivative work from,
                distribute, store in any medium (including in any other
                website), broadcast, transmit, re-transmit, modify, or show in
                public all or any part of the Content without the prior written
                permission of BDPL. In addition, you may not display any of the
                Content surrounded or framed or otherwise surrounded by material
                not originating from Us without Our consent. Any use of the
                Content not expressly permitted by these Terms is prohibited
                unless authorised in writing by BDPL.
              </li>
              <li>
                If you copy or download any part of the Content in breach of
                these Terms, your right to use the Tamasha Platform will cease
                immediately and you must, at our option, return or destroy any
                copies of the materials you have made.
              </li>
              <li>
                You agree not to make Content available on unauthorised websites
                or software applications and not to share content on P2P
                networks without our permission. We remind you that such conduct
                is prohibited by law and BDPL retains the unequivocal right to
                initiate immediate legal action in response to any such
                occurrence.
              </li>
              <li>
                The names, images and logos identifying BDPL and its businesses
                or third parties and their products and services are proprietary
                marks of BDPL and/or third parties. Nothing contained in these
                Terms shall be construed as conferring any licence or right
                under any trade mark of BDPL or any other third party.
              </li>
              <li>
                In addition to the restrictions set forth above, you must not
                use the Tamasha Platform to:
                <ol className="ml-3 mt-3 nested-lower-alpha" type="a">
                  <li>
                    post, upload or otherwise transmit or link to content that,
                    inter alia, is unlawful; threatening; abusive; obscene;
                    vulgar; sexually explicit; offensive; excessively violent;
                    invasive of another's privacy, publicity, contract or other
                    rights; tortious; false or misleading; defamatory; libelous;
                    hateful; or discriminatory;
                  </li>
                  <li>harass or harm another person;</li>
                  <li>exploit or endanger a minor;</li>
                  <li>
                    impersonate or attempt to impersonate any person or entity;
                  </li>
                  <li>
                    violate any patent, trademark, trade secret, copyright,
                    privacy, publicity or other proprietary rights;
                  </li>
                  <li>
                    introduce or engage in activity that involves the use of
                    viruses, software disabling codes, bots, worms, or any other
                    computer code, files or programs that interrupt, destroy, or
                    limit the functionality of any computer software or hardware
                    or telecommunications equipment, or otherwise permit the
                    unauthorized use of or access to a computer or a computer
                    network;
                  </li>
                  <li>
                    attempt to decipher, decompile, disassemble or reverse
                    engineer any of the software, applications and/or any
                    element comprising the Tamasha Platform and/or the Content;
                  </li>
                  <li>
                    interfere with, damage, disable, disrupt, impair, create an
                    undue burden on, or gain unauthorized access to the Tamasha
                    Platform or BDPL or BDPL's affiliates' or BDPL's service
                    providers' servers, networks or accounts;
                  </li>
                  <li>
                    cover, remove, disable, manipulate, block or obscure
                    advertisements or other portions of the Tamasha Platform or
                    the Content;
                  </li>
                  <li>
                    delete or revise any information provided by or pertaining
                    to any other user of the Tamasha Platform;
                  </li>
                  <li>
                    use technology or any automated system such as scripts,
                    spiders, offline readers or bots in order to collect or
                    disseminate usernames, passwords, email addresses or other
                    data from the Tamasha Platform or circumvent, delete,
                    deactivate, decompile, reverse engineer, disassemble or
                    modify any security technology or software that is part of
                    the Tamasha Platform;
                  </li>
                  <li>
                    send or cause to send (directly or indirectly) unsolicited
                    bulk messages or other unsolicited bulk communications of
                    any kind through the Tamasha Platform;
                  </li>
                  <li>
                    solicit, collect or request any personal information for
                    commercial or unlawful purposes or any purpose not
                    specifically permitted herein;
                  </li>
                  <li>
                    post, upload or otherwise transmit an image or video of
                    another person without that person's consent;
                  </li>
                  <li>
                    engage in commercial activity (including but not limited to
                    advertisements or solicitations of business; sales;
                    contests; sweepstakes; creating, recreating, distributing or
                    advertising basis any portion of the Content; or building a
                    business using the Content) without BDPL's prior written
                    consent;
                  </li>
                  <li>
                    use technology or other means to access, index, frame, or
                    link to the Tamasha Platform or the Content that is not
                    authorized by BDPL;
                  </li>
                  <li>
                    access the Tamasha Platform or the Content through any
                    automated means, including "robots", "spiders", or "offline
                    readers" (other than by individually performed searches on
                    publicly accessible search engines for the sole purpose of,
                    and solely to the extent necessary for, creating publicly
                    available search indices - but not caches or archives - of
                    the Tamasha Platform and excluding those search engines or
                    indices that host, promote, or link primarily to infringing
                    or unauthorized content);
                  </li>
                  <li>
                    use the Tamasha Platform or the Content to advertise or
                    promote services which compete with the Tamasha Platform;
                  </li>
                  <li>
                    use the Tamasha Platform for any commercial or business
                    related use or in any commercial establishment or area open
                    to the public (e.g., lobby, bar, restaurant, diner, stadium,
                    casino, club, cafe, theatre, etc.) or build a business
                    utilising the Tamasha Platform and/or Content, whether or
                    not for profit; use the Tamasha Platform or the Content in a
                    manner inconsistent with or in contravention of any and all
                    applicable law; and/or
                  </li>
                  <li>
                    attempt, facilitate, induce, aid and abet, or encourage
                    others to do any of the foregoing.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>ACCESSING THE TAMASHA PLATFORM</strong>
            <ol className="first-nested-list ml-4 pl-0">
              <li className="mt-3">
                You acknowledge and agree that BDPL may employ specific
                technologies for monitoring user activities, as outlined
                separately in the Privacy Notice. BDPL may offer access to its
                services through various models, including:
                <ol className="ml-3 mt-3 nested-lower-alpha" type="a">
                  <li>
                    Free access with potential advertisements or commercials;
                  </li>
                  <li>
                    Subscription-based access with payment according to the
                    selected subscription plan;
                  </li>
                  <li>
                    Subscription-based access with payment according to the
                    chosen subscription plan, with the ability to preview
                    certain promotional content before subscribing;
                  </li>
                  <li>
                    A pay-per-view model, with or without
                    advertisements/commercials; or
                  </li>
                  <li>
                    A combination of the aforementioned models on the Tamasha
                    Platform.
                  </li>
                </ol>
              </li>
              <li>
                You agree and recognize that the Tamasha Platform, including its
                features, layout, and interface, as well as the Content, may
                vary depending on location, time, and device, and are subject to
                various parameters such as device specifications, internet
                availability, speed, bandwidth, and more and factors that affect
                or influence the operation or use of the Tamasha Platform. The
                Tamasha Platform is continuously evolving and may change over
                time. BDPL reserves the right to make these changes or, if
                necessary, discontinue the Tamasha Platform. If required by law,
                BDPL may also suspend, restrict, or terminate user access to the
                Tamasha Platform.
              </li>
              <li>
                The Tamasha Platform can be accessed through the operating
                systems authorized by BDPL, which are listed in the{" "}
                <a
                  className="text-blue"
                  href="https://play.google.com/store/apps/details?id=com.spbtv.mobilinktv"
                  target="_blank"
                >
                  Google Play Store
                </a>{" "}
                and{" "}
                <a
                  className="text-blue"
                  href="https://apps.apple.com/pk/app/tamasha-live-tv-movie-drama/id976208250"
                  target="_blank"
                >
                  App Store
                </a>{" "}
                as current Compatible Systems. Whether a device qualifies as a
                Compatible System may, in some cases, depend on software or
                platforms provided or maintained by the device manufacturer or
                other third parties. Users are responsible for procuring,
                maintaining, and paying for all necessary hardware,
                telecommunications, and other services required for Tamasha
                Platform usage. When accessing the Tamasha Platform via a mobile
                network, users are subject to their network or roaming
                provider's messaging, data, and other rates and fees.
                Downloading, installing, or using the Tamasha Platform may be
                restricted or prohibited by the user's network provider or
                user's organisation (as applicable).
              </li>
              <li>
                BDPL may temporarily suspend user access to all or part of the
                Tamasha Platform at its discretion. BDPL will make reasonable
                efforts to restore access to the Tamasha Platform or the
                affected portion thereof as soon as practicable after temporary
                suspension. BDPL is not liable for any delays, delivery
                failures, damages, or losses resulting from limitations, delays,
                or other inherent problems associated with internet use and
                electronic communications, including user or third-party
                provider device faults, lack of connectivity, out-of-range
                issues, power-off conditions, or malfunctioning.
              </li>
              <li>
                The Tamasha Platform and/or certain Content may not be available
                for streaming or downloading at any given time, including during
                maintenance or update periods, technical issues, removal of
                content under instructions of relevant authority, power or
                server outages, or due to reasons beyond BDPL's reasonable
                control, such as Force Majeure events (e.g., acts of God, wars,
                diseases, epidemics, pandemics, lockdowns, quarantines,
                revolutions, riots, civil commotions, strikes, lockouts, floods,
                fires, technical failures, failure of public utilities, man-made
                disasters, changes or introductions of laws or regulations,
                governmental orders, etc.). BDPL disclaims any liability in such
                Force Majeure events. There may be instances where BDPL has to
                remove specific features, functionality, devices, or platforms
                from accessing the Tamasha Platform or Content. BDPL will
                endeavour to communicate any such changes, usage rules, and
                restrictions but acknowledges the right to make such changes at
                its sole discretion and without notice.
              </li>
              <li>
                In the ongoing assessment of the Tamasha Platform, BDPL may
                periodically experiment with, modify, or offer certain features
                or elements of the Tamasha Platform, including promotional
                features, user interfaces, plans, and pricing, for some or all
                users. BDPL may require users to upgrade or reinstall the
                application to continue using the Tamasha Platform, and users
                agree to comply with such requirements. Usage of updates,
                modifications, or replacement versions of the Tamasha Platform
                is governed by these Terms of Use and any additional terms
                agreed to during installation or acceptance of new terms.
              </li>
              <li>
                Content available on the Tamasha Platform may provoke varying
                reactions among users. Users may encounter Content they find
                offensive, indecent, explicit, or objectionable. Content types,
                genres, categories, descriptions, and separate sections for
                Content are provided as navigation aids and for informational
                purposes, with no guarantee of user agreement. Users acknowledge
                these risks and assume responsibility for their choices
                regarding the appropriateness of Content for themselves and
                their families when using the Tamasha Platform.
              </li>
              <li>
                Content on the Tamasha Platform may include flashing light
                sequences or patterns that could affect users susceptible to
                photosensitive epilepsy or other photo sensitivities or related
                conditions. Additionally, 4K Ultra HD content versions may
                feature increased brightness and colour saturation, potentially
                affecting users. Users acknowledge these features and their
                corresponding risks or impacts.
              </li>
              <li>
                Various technologies are employed to deliver an optimal viewing
                experience, including SD, HD, and 4K Ultra HD quality options
                for specific Content and Subscription plans. However, the
                playback quality of Content, including resolution, may be
                influenced by factors such as Content format, user location,
                internet bandwidth, internet service terms, Subscription plans,
                and device configurations, among others. The time required to
                commence viewing Content varies based on factors such as user
                location, Subscription plan, internet bandwidth, the number of
                devices concurrently connected to the same network, selected
                Content, and device settings. Consequently, no warranties can be
                provided by BDPL concerning Content in these aspects.
              </li>
              <li>
                Users must not access or use the Tamasha Platform or its Content
                through mechanisms or technologies that conceal their actual
                geographic location, provide inaccurate location details, or
                through virtual private networks (or similar mechanisms). BDPL
                assumes no responsibility or liability for the collection,
                storage, or processing of user personal information or data if
                users employ mechanisms or technologies to access or use the
                Tamasha Platform or its Content while concealing their actual
                geographic location.
              </li>
            </ol>
          </li>
          <li>
            <strong>USING THE TAMASHA PLATFORM</strong>
            <ol className="first-nested-list ml-4 pl-0">
              <li className="mt-3">
                Your use of the Tamasha Platform is subject to your compliance
                with these Terms, the Privacy Notice, and all applicable laws,
                rules, and regulations.
              </li>
              <li>
                To be able to subscribe to the Tamasha Platform, you must have
                reached the age of majority and be competent to contract under
                the applicable laws.
              </li>
              <li>
                If you permit anyone else to use, view, or access the Tamasha
                Platform and/or the Content using your account, you acknowledge
                that: (1) some content offered on the Tamasha Platform may not
                be suitable for children or for some viewers and therefore
                discretion is advised; and (2) these Terms as well as any
                consents you may have provided also applies to their access,
                use, and disclosure of information.
              </li>
              <li>
                Only persons of the majority age and above are allowed to
                register any account on the Tamasha Platform and will always be
                responsible for the operation of any such account. Your access
                to and use of the Tamasha Platform is subject to these Terms.
              </li>
              <li>
                By accessing or using the Tamasha Platform:
                <ol className="ml-3 mt-3 nested-lower-alpha" type="a">
                  <li>
                    You confirm and warrant that all the data and information
                    you supply to BDPL and or any BDPL's affiliates
                    ("Registration Data") is accurate in all respects.
                  </li>
                  <li>
                    You agree to promptly update your Registration Data as
                    necessary, so that your Registration Data remains accurate
                    at all times.
                  </li>
                  <li>
                    You acknowledge that BDPL contracts with you and is
                    responsible for distributing the Tamasha Platform in
                    Pakistan and that BDPL is the data controller as set out in
                    detail in the Privacy Notice accessible at{" "}
                    <strong>
                      <a
                        href="/privacy-policy"
                        target="_blank"
                      >
                        “Privacy Policy”
                      </a>
                    </strong>
                  </li>
                </ol>
              </li>
              <li>
                When you use the Tamasha Platform, you may be given the
                opportunity to consent to or opt out from receiving
                communications from us through email, text, and/or mobile push
                notifications or any other method as BDPL may provide. Standard
                text and calling rates will apply. You agree that texts, calls,
                or pre-recorded messages may be generated by automatic telephone
                dialing systems. For details on opting out of marketing
                material, kindly refer to the Privacy Notice accessible at
                available at{" "}
                <strong>
                  <a
                    href="/privacy-policy"
                    target="_blank"
                  >
                    “Privacy Policy”
                  </a>
                </strong>
                . You acknowledge that you are not required to consent to
                receive promotional texts, emails, or calls or any other
                communication as a condition of using the Tamasha Platform.
              </li>
              <li>
                When you use the Tamasha Platform, you may grant certain
                permissions to BDPL for your device and/or accounts. Most mobile
                device platforms provide additional information regarding these
                permissions and how, if possible, to change your permission
                settings. By downloading, installing, or using the Tamasha
                Platform, you agree to receive automatic software updates (as
                applicable).
              </li>
              <li>
                You understand that the Tamasha Platform is for your personal,
                non-commercial use and is intended for informational and/or
                entertainment purposes only; the Content available does not
                constitute legal, financial, professional, medical, or
                healthcare advice or diagnosis and cannot be used for such
                purposes.
              </li>
              <li>
                Except as expressly licensed, we do not allow uses of the
                Tamasha Platform, Content, or other BDPL or BDPL affiliate's
                intellectual property that are commercial or business-related,
                including used in marketing or branding, or that advertise or
                offer to sell or promote products or services (whether or not
                for profit), or that solicit others (including solicitations for
                contributions or donations).
              </li>
              <li>
                You agree not to knowingly or recklessly introduce a virus or
                other harmful component, or otherwise tamper with, impair or
                damage the Tamasha Platform or connected network, or interfere
                with any person or entity's use or enjoyment of the Tamasha
                Platform.
              </li>
            </ol>
          </li>
          <li>
            <strong>SUBSCRIPTION SERVICES</strong>
            <ol className="first-nested-list ml-4 pl-0">
              <li className="mt-3">
                Some or all of the Content made available on the Tamasha
                Platform can be accessed only by registered users who have
                registered for a subscription plan ("
                <strong>Subscription User</strong>"). You need to register for a
                subscription plan on the Tamasha Platform or through our
                approved distributors and/or service providers to access or use
                this Content ("<strong>Subscription</strong>"). To become a
                Subscription User, you may be required to provide certain
                personal information to BDPL and may be required to make
                payments ("<strong>Subscription Fee</strong>") through a credit
                card, digital wallet, or other payment method accepted by BDPL
                ("<strong>Payment Method(s)</strong>") that you have selected.
                For payment using a credit card or such other Payment Method as
                decided by BDPL from time to time, by signing up for a
                Subscription, you agree that your Subscription will be
                automatically renewed and, unless you cancel your Subscription,
                you authorize us to charge your Payment Method for the renewal
                term. The period of auto-renewal will be the same as your
                initial Subscription period unless otherwise disclosed to you.
                The renewal rate will be no more than the rate for the
                immediately prior Subscription period, excluding any promotional
                and discount pricing, unless we notify you of a rate change
                prior to your auto-renewal, in which case you will have the
                right to cancel the renewal of your Subscription. Some Payment
                Methods may not be available for all Subscription plans.
              </li>
              <li>
                You understand and acknowledge that not all Payment Methods are
                being charged for automatic renewal and your Subscription may
                NOT be automatically renewed upon expiry of the Subscription
                period, based on the Payment Method being used by you for the
                Subscription. Unless your Payment Method is charged
                automatically at the time of renewal, you will be required to
                make a payment for the Subscription Fees to continue your
                Subscription.
              </li>
              <li>
                You acknowledge that BDPL uses third parties to process and
                facilitate the payment of your Subscription Fee. BDPL will
                notify you if you have successfully registered for a
                Subscription. You acknowledge that BDPL may offer different
                kinds of Subscription plans, each of which will be subject to
                different limitations and restrictions, and the Subscription Fee
                for each of these Subscription plans may vary. As mentioned in
                the preceding paragraph, you understand and acknowledge the
                Payment Method chosen by you may not enable BDPL to charge your
                Payment Method at the time of renewal, and you may have to make
                a payment for the Subscription Fees for the renewal to continue
                your Subscription of the Tamasha Platform.
              </li>
              <li>
                Your Subscription will continue until you cancel it or your
                Subscription period has expired or it is terminated by BDPL in
                accordance with these Terms. Please see below for information on
                your rights to cancel your Subscription.
              </li>
              <li>
                BDPL may change your Subscription (including the Subscription
                period), the Subscription Fees and/or your billing cycle by
                giving you advance notice for any such changes. If you do not
                agree with any change, you may cancel your Subscription.
                However, please note that your cancellation of your Subscription
                shall take effect at the expiry of the current Subscription
                period or as decided by BDPL. Any changes to your Subscription
                Fee or your billing cycle will be effective only after the
                expiry of the current Subscription period for which you have
                already been billed or as decided by BDPL. Accordingly, the
                revised Subscription Fee or billing cycle shall be effective
                only after the expiry of the current Subscription period or as
                decided by BDPL.
              </li>
              <li>
                Any personal information whether provided by you as a part of
                the Subscription registration (including any payment-related
                information) process, or collected automatically when you use
                the Tamasha Platform, will be governed by the Privacy Notice,
                accessible at{" "}
                <strong>
                  <a
                    href="/privacy-policy"
                    target="_blank"
                  >
                    “Privacy Policy”
                  </a>
                </strong>
                . We reserve the right to, and you agree that we may, release
                your details to system administrators at other sites and to law
                enforcement agencies in order to assist them in resolving
                security incidents and violations of law.
              </li>
              <li>
                From time to time, BDPL may offer a free trial subscription for
                the Tamasha Platform. If you register for a free trial
                subscription, we will begin to bill your account when the free
                trial subscription expires, unless you cancel your subscription
                before that time. If applicable, the free trial period will last
                for such a period as is notified to you by BDPL when you
                register for the Subscription. Your eligibility for a free trial
                including the duration of such a free trial and the Content
                available to be viewed during the free trial period is at the
                discretion of BDPL. If any free trial is modified, suspended or
                terminated by BDPL, you will have the opportunity to cancel your
                Subscription. You may cancel your Subscription at any time
                during the free trial period, failing which you will be deemed
                to have elected to continue with the Subscription, and BDPL will
                be entitled to charge the Subscription Fee for the applicable
                billing cycle to the Payment Method available and selected by
                you. You will not receive a separate notice that your free trial
                is about to end or has ended, or that your paid Subscription has
                begun. Availability of a free trial is not guaranteed and, if
                available, is only available to those who have not previously
                used one for the Tamasha Platform or may be available only on
                select Payment Methods.
              </li>
              <li>
                BDPL reserves the right to make certain Content accessible to
                non-registered and/or guest users on a promotional basis or free
                trial basis. BDPL may at its sole discretion determine such
                Content and the duration for which it is made available without
                the requirement of payment of Subscription Fees.
              </li>
              <li>
                If you cancel your Subscription before the end of the free trial
                period you will not be entitled to any compensation, monetary or
                otherwise, from BDPL for the un-used period of the free trial.
                However, you will still be able to enjoy your free trial until
                the expiry of the free-trial period.
              </li>
            </ol>
          </li>
          <li>
            <strong>PAYMENTS</strong>
            <ol className="first-nested-list ml-4 pl-0">
              <li className="mt-3">
                For payment using credit cards or such other Payment Method as
                decided by BDPL from time to time, BDPL will automatically
                charge your selected Payment Method for the Subscription Fee
                payable by you through a third-party payment gateway at the
                beginning of your Subscription or at the end of any free trial
                period (unless you cancel your subscription prior to the end of
                the free trial period or the Subscription period), and
                automatically again on each subsequent renewal of your
                Subscription unless you cancel your Subscription, or your
                Subscription otherwise expires, is suspended or discontinued by
                BDPL in accordance with these Terms of Use.
              </li>
              <li>
                You understand and acknowledge that not all Payment Methods are
                being charged for automatic renewal and your Subscription may
                NOT be automatically renewed upon expiry of the Subscription
                period, based on the Payment Method being used by you for the
                Subscription. Unless your Payment Method is charged
                automatically at the time of renewal, you will be required to
                make a payment for the Subscription Fees to continue your
                Subscription. To the extent permitted by applicable law, you
                also agree that we may charge your Payment Method on file if you
                decide to restart your Subscription. On cancellation of a
                Subscription, you will be notified by BDPL (for further details
                on cancellations, please see below under ‘Cancellation &amp;
                Refund’). To see the commencement date of your next renewal
                period and information on your billing cycle, you should visit
                the Subscription &amp; Payments section of the Tamasha Platform.
                Once you have made a payment, it may take a few days for your
                payment made to BDPL to be reflected in your Subscription &amp;
                Payments section.
              </li>
              <li>
                You will be able to change your Payment Method only when your
                Subscription expires and you renew Subscription, unless
                determined otherwise by BDPL.
              </li>
              <li>
                You understand and acknowledge that BDPL facilitates a
                third-party payment gateway for processing of payments. This
                facility is managed by a third-party payment gateway provider.
                For certain Payment Methods, you are required to follow all the
                terms and conditions of such third-party payment gateway
                provider which are referred to on the Tamasha Platform and/or
                such third-party platform, if specified therein. You
                acknowledge, that you are additionally bound by the terms and
                conditions of the third-party payment gateway and/or such
                third-party platform as per the Payment Method used for the
                payment of the Subscription Fees. You can file any complaint or
                question related to payment processing by contacting customer
                support services and it will be forwarded to the third-party
                payment gateway provider concerned for a response.
              </li>
              <li>
                You are responsible for the accuracy and authenticity of the
                payment information you provide including the bank account
                number/credit card details and any other information requested
                during the subscription process. You represent and warrant that
                you have the right to use any credit card or other payment
                information (including relating to any Payment Method) that you
                submit. You agree and acknowledge that BDPL shall not be liable
                and in no way be held responsible for any losses whatsoever,
                whether direct, indirect, incidental or consequential, including
                without limitation any losses due to delay in processing of
                payment instruction or any credit card fraud.
              </li>
              <li>
                Upon payment being received by BDPL through any of the Payment
                Method, BDPL shall make the relevant Subscription available to
                you through any of the approved modes of delivery that BDPL
                adopts. It is hereby clarified that the Subscription(s) offered
                to you are offered by BDPL and not by any third party including
                any payment gateway service providers.
              </li>
              <li>
                The Subscription Fees specified for each Subscription plan are
                inclusive of all applicable taxes (including VAT). We will not
                be able to notify you of any changes in applicable taxes. You
                are responsible for all third-party internet access charges,
                bank charges and taxes not included within the Subscription Fee
                in connection with your use of the Tamasha Platform, which may
                differ based on your service provider. Please check with your
                internet provider, bank, Payment Method service provider for
                information for such charges.
              </li>
            </ol>
          </li>
          <li>
            <strong>CANCELLATION &amp; REFUNDS</strong>
            <ol className="first-nested-list ml-4 pl-0">
              <li className="mt-3">
                If you cancel your Subscription, the Subscription Fees billed
                for your current Subscription plan are non-refundable. However,
                you will still be able to enjoy your Subscription until the
                expiry of the Subscription period. BDPL shall not be responsible
                for Users being double billed if they choose to subscribe to
                more than one Subscription plan at the same time.
              </li>
              <li>
                If you cancel your Subscription or your Subscription expires,
                and you wish to re-subscribe, you may do so from your
                Subscription &amp; Payments page on the Tamasha Platform.
              </li>
              <li>
                You can cancel your Subscription at any time before expiry date
                of your current Subscription, by selecting “cancel” within
                Subscription &amp; Payments page on the Tamasha Platform and
                proceeding with the onscreen prompts. For more details on how to
                cancel your Subscription you may contact our customer support
                services.
              </li>
            </ol>
          </li>
          <li>
            <strong>ADVERTISEMENTS, CONTESTS AND PROMOTIONS</strong>
            <ol className="first-nested-list ml-4 pl-0">
              <li className="mt-3">
                BDPL does not represent and warrant that advertisements of the
                products or services on the Tamasha Platform or the (third
                party) content made available / hosted on the third-party sites
                are true and correct. Accordingly, BDPL takes no responsibility
                over and expressly disclaims any liability arising out of, the
                advertisements or usage of such advertised products or services.
                Any dealings you have with advertisers due to your use of the
                products or services advertised on the Tamasha Platform are
                between you and the advertiser, and you agree that BDPL is not
                liable for any loss or claim that you may have against an
                advertiser.
              </li>
              <li>
                BDPL may notify additional terms and conditions (“Additional
                Terms and Conditions”), from time to time, in connection with
                your: (a) participation in any contest, game, or other promotion
                on the Tamasha Platform conducted by BDPL and/or BDPL's
                affiliates and/or any third party partner authorized by BDPL,
                and/or, (b) participation in any contest, or promotion, in
                connection with Tamasha Platform integrated with a third-party
                website, service, application, platform, and/or, (c) viewing of
                certain Content. If such Additional Terms and Conditions
                conflict with these Terms, the provisions contained in the
                Additional Terms and Conditions will govern and control.
              </li>
            </ol>
          </li>
          <li>
            <strong>DEMNITY</strong>
            <ol className="first-nested-list ml-4 pl-0">
              <li className="mt-3">
                You agree to defend, indemnify and hold harmless BDPL, its
                affiliates, officers, directors, employees and agents, from and
                against any and all claims, damages, obligations, losses,
                liabilities, costs or debt, and expenses (including but not
                limited to attorneys' fees on a solicitor-client basis) arising
                from: (i) your use of and access to the Tamasha Platform and/or
                Content and/or any related services; (ii) your violation of any
                term of these Terms of Use; (iii) your violation of any third
                party rights, including without limitation any publicity,
                privacy, or intellectual property right; (iv) your breach of any
                applicable laws; and/or (v) any unauthorized, improper, illegal
                or wrongful use of your account by any person, including a third
                party, whether or not authorized or permitted by you.
              </li>
            </ol>
          </li>
          <li>
            <strong>DISCLAIMERS AND LIMITATION OF LIABILITY</strong>
            <ol className="first-nested-list ml-4 pl-0">
              <li className="mt-3">
                You agree that use of and access to the Tamasha Platform is at
                your own risk. The Tamasha Platform including the Content and
                any other materials contained on or provided through the Tamasha
                Platform are provided "AS IS" and, to the fullest extent
                permitted by law, are provided without warranties of any kind,
                either express or implied. Without limiting the foregoing, BDPL
                does not make any warranties of fitness for a particular
                purpose, title, merchantability, completeness, availability,
                security, compatibility or non-infringement; or that the Tamasha
                Platform will be uninterrupted, free of viruses, malware or
                other harmful components, accurate, error free or reliable.
              </li>
              <li>
                In no case shall BDPL, its directors, officers, employees,
                affiliates, agents, contractors, principals, or licensors be
                liable for any direct, indirect, incidental, punitive, special,
                or consequential damages arising from your use of the services
                or any content or material thereof or for any other claim
                related in any way to your access of or use of the BDPL products
                and/or services or any content or material thereof, including,
                but not limited to, any errors or omissions in any content or
                material, or any loss or damage of any kind incurred as a result
                of the use of the BDPL products and/or services or any content
                or material thereof, even if advised of their possibility. To
                the extent permitted under laws, BDPL'S monetary liability for
                the services or the content or material thereof on the tamasha
                platform shall be limited to the subscription fee paid. Where
                the laws do not allow the exclusion or the limitation of
                liability for consequential or incidental damages, in such
                jurisdictions, BDPL'S liability shall be limited to the extent
                permitted by law.
              </li>
            </ol>
          </li>
          <li>
            <strong>TERMINATION OF SERVICE</strong>
            <ol className="first-nested-list ml-4 pl-0">
              <li className="mt-3">
                BDPL reserves the right to immediately terminate, suspend,
                limit, or restrict your Subscription or your use of the BDPL
                Products and/or access to the Tamasha Platform and/or Content at
                any time, without notice or liability to you or any other
                person, if you breach these Terms, violate any law, rule, or
                regulation, or engage in any inappropriate conduct in relation
                to the Tamasha Platform including the Content or your
                Subscription.
              </li>
              <li>
                Without limiting the above, BDPL may restrict or suspend your
                access to your Tamasha Platform account for cause without notice
                to you, which cause includes but is not limited to (a) requests
                from law enforcement or other government authorities, (b)
                unexpected technical issues or problems, or (c) if BDPL
                reasonably believes that your Tamasha Platform account has been
                created fraudulently, or anyone uses your Tamasha Platform
                account to commit fraud or for any purpose other than its
                intended purpose and in accordance with all of the requirements
                applicable thereto.
              </li>
              <li>
                To the fullest extent permitted by law, you agree that BDPL
                and/or its affiliates will not be liable to you or to any third
                party for any such restriction, suspension or termination of
                your access to your Tamasha Platform account and/or Content.
              </li>
              <li>
                Upon termination of your Tamasha Platform account, whether
                terminated by BDPL or lapse of time or at your request (other
                than a cancellation of your subscription, in which case your
                Subscription will continue to the end of the billing period),
                you will immediately lose the right to access streamed content
                through the Tamasha Platform and within a limited period will
                lose the right to view Tamasha Platform content downloaded
                within or through the Tamasha Platform.
              </li>
            </ol>
          </li>
          <li>
            <strong>INFORMATION OR COMPLAINTS</strong>
            <ol className="first-nested-list ml-4 pl-0">
              <li className="mt-3">
                If you have any questions about these Terms of Use, the Privacy
                Notice, or your dealings with BDPL, or a question or a complaint
                about the Tamasha Platform and/or the Content, you can contact
                customer support services by also providing us with your phone
                number used for accessing your Tamasha Platform, as below:
                <p>
                  <strong>WhatsApp Support: </strong>{" "}
                  <a href="https://wa.me/03003111119" target="_blank">
                    0300-3111119
                  </a>
                </p>
                <p>
                  <strong>Email: </strong>{" "}
                  <a
                    href="mailto:customercare@beyonddigital.com.pk"
                    target="_blank"
                  >
                    customercare@beyonddigital.com.pk
                  </a>
                </p>
              </li>
            </ol>
          </li>
          <li>
            <strong>
              GOVERNING LAW, DISPUTE RESOLUTION AND LIMITATION OF LIABILITY
            </strong>
            <ol className="first-nested-list ml-4 pl-0">
              <li className="mt-3">
                These Terms of Use, including any dispute, controversy,
                proceeding, or claim of whatever nature which may arise out of
                or in connection with these Terms of Use, are governed by the
                laws of Pakistan without regard to the conflict of laws
                principle, and are subject to the disclaimer of warranties and
                limitation of liability as set out herein.
              </li>
              <li>
                You agree to these Terms of Use on behalf of yourself and all
                members of your household and others who use the Tamasha
                Platform under your account by using the Tamasha Platform.
              </li>
              <li>
                Without limiting the disclaimer of warranties and limitation of
                liability in these Terms of Use: (i) in no event shall our or
                our licensors' total liability to you for any and all damages
                arising out of or related to your use or inability to use the
                Tamasha Platform exceed the amount of Pakistani Rupees One
                Thousand (PKR 1,000); and (ii) in no event shall our or our
                total liability to you for all damages arising from your use of
                the Tamasha Platform, , or information, materials or products
                included on or otherwise made available to you through the
                Tamasha Platform, exceed the amount you paid to us over the past
                12 months, in relation to your claim for damages.
              </li>
            </ol>
          </li>
          <li>
            <strong>ADDITIONAL PROVISIONS</strong>
            <ol className="first-nested-list ml-4 pl-0">
              <li className="mt-3">
                If any provision of these Terms is or are held invalid, void, or
                unenforceable, then that provision shall be considered severable
                from the remaining provisions, and the remaining provisions
                given full force and effect.
              </li>
              <li>
                BDPL reserves the right, at any time and from time to time, upon
                notification to you, to update, revise, supplement, and
                otherwise modify these Terms including the Privacy Notice and to
                impose new or additional rules, policies, terms, or conditions
                on your use of the Tamasha Platform ("
                <strong>Additional Terms</strong>").
              </li>
              <li>
                Subject to BDPL complying with these requirements, your
                continued use of the Tamasha Platform will be deemed to
                constitute your acceptance of any and all such Additional Terms.
                If you do not agree with any Additional Terms, you should cease
                using the Tamasha Platform and cancel your Subscription before
                the Additional Terms take effect. If you cancel your
                Subscription for this reason, the cancellation will take effect
                on the expiry of the current Subscription.
              </li>
              <li>
                Any Additional Terms will not apply to any dispute between BDPL
                and you arising prior to the date on which such Additional Terms
                became effective.
              </li>
              <li>
                The provisions of these Terms, other than the obligations on
                BDPL to provide you with access to the Tamasha Platform and the
                Content, your rights to receive or enjoy the Content and your
                obligations to pay the Subscription Fee, will survive the
                termination or expiry of your Subscription and/or these Terms of
                Use.
              </li>
              <li>
                These Terms do not, and shall not be construed to, create any
                partnership, joint venture, employer-employee, agency or
                franchisor-franchisee relationship between you and BDPL.
              </li>
              <li>
                You may not assign, transfer or sublicense any or all of your
                rights or obligations under these Terms without BDPL's express
                prior written consent. BDPL may assign, transfer or sublicense
                any or all of its rights or obligations under these Terms to any
                person or entity at any time.
              </li>
              <li>
                No waiver by you or BDPL of any breach or default under these
                Terms will be deemed to be a waiver of any preceding or
                subsequent breach or default.
              </li>
              <li>
                Any heading, caption or section title contained herein is for
                convenience only, and in no way defines or explains any section
                or provision.
              </li>
              <li>
                Any words following the terms including, include, in particular,
                for example or any similar expression shall be construed as
                illustrative and shall not limit the sense of the words,
                description, definition, phrase or term preceding those terms.
              </li>
              <li>
                Notices to you (including notices of changes to these Terms of
                Use) may be made via posting to the Tamasha Platform or by
                issuing e-mail or messages to your designated phone number
                (example: SMS), push notifications, in-app notifications
                (including in each case via links), or by regular post.
              </li>
              <li>
                Without limitation, a printed version of these Terms and of any
                notice given in electronic form shall be admissible in judicial
                or administrative proceedings based upon or relating to these
                Terms to the same extent and subject to the same conditions as
                other business documents and records originally generated and
                maintained in printed form.
              </li>
              <li>
                These Terms and the Privacy Notice constitute the entire
                agreement between you and BDPL governing your use of the Tamasha
                Platform, superseding any prior agreements between you and BDPL
                regarding such use. Further, your acceptance of these Terms
                constitutes a valid and binding agreement between you and BDPL.
                Should these Terms of Use require to be stamped under any
                applicable stamp duty laws, you should bear such stamp duty
                payable and comply with relevant stamping obligations and BDPL
                shall not be responsible for such stamp duty payments.
              </li>
            </ol>
          </li>
          <strong>Last updated: 13-11-23</strong>
        </ol>
      </div>
    </div>
  );
}

export default Terms;
