import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ApiNames from "../Constants/ApiNames";
import { encode as base64_encode } from "base-64";
import { fetchGet } from "../utils/FetchApi";
import tagManagerEvents from "../utils/GoogleTagManager";
import Awareness from "../pages/Awareness";

type Props = {
  token: string | null;
};

let adNetwork = "";
const AwarenessScreen = (props: Props) => {
  const [isHidden, setIsHidden] = useState(true);
  const search = useLocation().search;
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);

  const qsPackageId = searchParams.get("package_id")
    ? searchParams.get("package_id")
    : "1";
  useEffect(() => {
    if (props.token) {
      getHe();
    }
  }, [props.token]);
  const getHe = () => {
    fetch(
      "http://www.dot-jo.biz/appgw/GetPartnerHEMSISDN?partnerId=tamasha-417a6b72"
    )
      .then((response) => response.json()) // Parse the JSON from the response
      .then((data) => {
        const utm_source: any = new URLSearchParams(search).get("utm_source");
        if (data?.errorCode === "0" && data?.encMSISDN) {
          if (utm_source) {
            checkUtm(utm_source);
          }
          authFlow(utm_source, data.encMSISDN, data.heId);
        } else {
          authFlow(utm_source, null, null);
        }
      })
      .catch((error) => {
        navigateToHome();
      });
  };

  const checkUtm = async (_utmSource: string | null) => {
    try {
      let response = await fetchGet(ApiNames.utm, props.token);
      if (response.status === 200) {
        {
          response.response.map((e: any) => {
            if (_utmSource === e.sf_utm) {
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  const authFlow = async (
    _utmSource: any,
    msisdn: string | null,
    heId: string | null
  ) => {
    try {
      let _qsPackageId = "1";
      if (qsPackageId === "1" || qsPackageId === "2" || qsPackageId === "3") {
        _qsPackageId = qsPackageId;
      }
      let response = await fetchGet(
        ApiNames.awarenessFlow +
          "?utm_source=" +
          _utmSource +
          "&msisdn=" +
          msisdn +
          "&heId=" +
          heId +
          "&package_id=" +
          _qsPackageId,
        props.token
      );
      if (response.status === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          if (qsPackageId === "1") {
            tagManagerEvents("zsubscribe", "ztiktok");
          } else if (qsPackageId === "2") {
            tagManagerEvents("zsubscribe", "ztiktokWeekly");
          } else if (qsPackageId === "3") {
            tagManagerEvents("zsubscribe", "ztiktokMonthly");
          } else {
            tagManagerEvents("subscribe", _utmSource);
          }
        } else if (adNetwork.toLowerCase() === "google") {
          tagManagerEvents("zsubscribe", "zoptimus");
        } else {
          tagManagerEvents("subscribe", _utmSource);
        }
        setIsHidden(false);
      } else {
        navigateToHome();
        tagManagerEvents("signupFailed", _utmSource);
      }
    } catch (err) {
      navigateToHome();
    }
  };
  const handleOverLay = () => {
    setIsHidden(true);
  };

  const navigateToHome = () => {
    let url = window.location.origin;
    url = `${url}/landing`;
    if (url.includes("http:")) {
      url = url.replace("http", "https");
    }
    // window.location.replace(url);
  };

  return <Awareness isHidden={isHidden} handleOverLay={handleOverLay} />;
};

export default AwarenessScreen;
