import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Terms from "./ZApp/pages/Terms";
import AwarenessScreen from "./ZApp/Screens/AwarenessScreen";
import LandingScreen from "./ZApp/Screens/LandingScreen";
import TagManager from "react-gtm-module";
import PrivacyPolicy from "./ZApp/pages/PrivacyPolicy";
import ApiNames from "./ZApp/Constants/ApiNames";
import { fetchPost } from "./ZApp/utils/FetchApi";
import SubscriptionScreen from "./ZApp/Screens/SubscriptionScreen";

function App() {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-5XR2S6FB",
    };
    TagManager.initialize(tagManagerArgs);
    handleGenerateToken();
  }, []);

  // handleGenerateToken
  const handleGenerateToken = async () => {
    try {
      let data = {
        username: process.env.REACT_APP_UserName_Zong,
        password: process.env.REACT_APP_Password_Zong,
      };
      const response = await fetchPost(
        `${ApiNames.authenticateWeb}`,
        null,
        data
      );
      if (response.status === 0) {
        setToken(response.response.token);
      } else {
        console.log("Something went wrong");
      }
    } catch (error) {
      console.log("handleGenerateToken", error);
    }
  };
  return (
    <>
      <Routes>
        <Route
          path="zongtamasha/*"
          element={<Navigate to={"/zongtamasha/landing"} />}
        />
        <Route
          path="/zongtamasha/landing"
          element={<SubscriptionScreen token={token} />}
        />
        {/* <Route path="/zongtamasha/*" element={<LandingScreen token={token} />} />
        <Route
          path="/zongtamasha/landing"
          element={<LandingScreen token={token} />}
        />
        <Route
          path="/zongtamasha/awareness"
          element={<AwarenessScreen token={token} />}
        /> */}
        <Route path="/zongtamasha/terms-of-use" element={<Terms />} />
        <Route path="/zongtamasha/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </>
  );
}

export default App;
