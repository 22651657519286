import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Terms from "./JApp/pages/Terms";
import AwarenessScreen from "./JApp/Screens/AwarenessScreen";
import LandingScreen from "./JApp/Screens/LandingScreen";
import { fetchApiPost } from "./JApp/utils/FetchApi";
import { ApiName } from "./JApp/utils/ApiName";
import TagManager from "react-gtm-module";
import PrivacyPolicy from "./JApp/pages/PrivacyPolicy";
import SubscriptionScreen from "./JApp/Screens/SubscriptionScreen";
import LandingJazzCashScreen from "./JApp/Screens/LandingJazzCashScreen";
import JazzCash from "./JApp/Screens/JazzCash";

function Temp() {
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-5XR2S6FB",
    };
    TagManager.initialize(tagManagerArgs);
    handleGenerateToken();
  }, []);

  // handleGenerateToken
  const handleGenerateToken = async () => {
    try {
      let data = {
        username: process.env.REACT_APP_UserName,
        password: process.env.REACT_APP_Password,
      };
      const response = await fetchApiPost("", `${ApiName.generateToken}`, data);
      if (response.status === 0) {
        setToken(response.response.token);
      } else {
        console.log("Something went wrong");
      }
    } catch (error) {
      console.log("handleGenerateToken", error);
    }
  };
  return (
    <>
      <Routes>
        <Route path="/*" element={<Navigate to={"/landing"} />} />
        <Route path="/landing" element={<SubscriptionScreen token={token} />} />
        <Route
          path="/landingjazzcash"
          element={<LandingJazzCashScreen token={token} />}
        />
        <Route path="/jazzcash" element={<JazzCash />} />

        {/* <Route path="/landing" element={<LandingScreen token={token}/>} />
          <Route path="/awareness" element={<AwarenessScreen token={token}/>} /> */}
        <Route path="/terms-of-use" element={<Terms />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </>
  );
}

export default Temp;
