import React, { useEffect, useState } from "react";
import tagManagerEvents from "../utils/GoogleTagManager";
import { Navigate, useLocation } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import { fetchGet, fetchPost } from "../utils/FetchApi";
import ApiNames from "../Constants/ApiNames";

type Props = {
  token: string | null;
};

let adNetwork = "";
let encodeMsisdn = "";
const LandingScreen = (props: Props) => {
  const [phoneNo, setPhoneNo] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [showError, setShowError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [validNum, setValidNum] = useState(true);
  const [timer, setTimer] = useState(30);
  const [seconds, setSeconds] = useState(30);
  const [isResendClicked, setResendClicked] = useState(false);
  const [isHidden, setIsHidden] = useState(true);
  const [heId, setHeId] = useState("");
  const [otpId, setOtpId] = useState("");
  const [otp, setOtp] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
  });

  const [inputRef, setInputRef] = useState<React.RefObject<HTMLInputElement>[]>(
    [
      React.createRef<HTMLInputElement>(),
      React.createRef<HTMLInputElement>(),
      React.createRef<HTMLInputElement>(),
      React.createRef<HTMLInputElement>(),
    ]
  );
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const utmSource: any = searchParams.get("utm_source");
  const qsPackageId = searchParams.get("package_id")
    ? searchParams.get("package_id")
    : "1";
  const [heUser, setHeUser] = useState(false);

  useEffect(() => {
    if (isResendClicked) {
      setSeconds(30);
      setResendClicked(false);
    }

    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(interval);
        }
        return prevSeconds > 0 ? prevSeconds - 1 : 0;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [isResendClicked]);

  useEffect(() => {
    let interval: string | number | NodeJS.Timeout | undefined;
    if (timer > 0) {
      interval = setTimeout(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => clearTimeout(interval);
  }, [timer]);

  useEffect(() => {
    if (props.token) {
      getHe();
      checkUtm();
    }
  }, [props.token]);
  const getHe = () => {
    fetch(
      "http://www.dot-jo.biz/appgw/GetPartnerHEMSISDN?partnerId=tamasha-417a6b72"
    )
      .then((response) => response.json()) // Parse the JSON from the response
      .then((data) => {
        if (data?.errorCode === "0" && data?.encMSISDN) {
          setHeUser(true);
          encodeMsisdn = data.encMSISDN;
          setHeId(data.heId);
          authFlow(data.encMSISDN);
        } else {
          authFlow(null);
        }
      })
      .catch((error) => {});
  };
  const authFlow = async (msisdn: string | null) => {
    try {
      let response = await fetchGet(
        ApiNames.GetFlow + "?utm_source=" + utmSource + "&msisdn=" + msisdn,
        props.token
      );
      if (response.status === 0) {
        handleResponseHeaders(response);
      }
    } catch (err) {}
  };

  const handleResponseHeaders = (response: any) => {
    try {
      if (response.status === 0) {
        if (
          response.result.isSpecialFlow === true &&
          response.result.msisdn !== "" &&
          response.result.otp !== ""
        ) {
          let phoneNumber = response.result.msisdn;
          let _qsPackageId = "1";
          if (
            qsPackageId === "1" ||
            qsPackageId === "2" ||
            qsPackageId === "3"
          ) {
            _qsPackageId = qsPackageId;
          }
          let data_ = {
            msisdn: phoneNumber,
            heId: heId,
            otp: response.result.otp,
            subPackage: "sub1",
            package_id: _qsPackageId,
            utm_source: utmSource,
            nick: "GW-guestUser",
          };

          let Api_name = response.result.fastFlow
            ? ApiNames.subscribeFastFlow
            : ApiNames.heSubscribeWeb;
          subscribeUser(Api_name, data_);
        } else if (
          response.result.isSpecialFlow === false &&
          response.result.msisdn
        ) {
          // 4g user without special flow
          let phoneNumber = response.result.msisdn;
          setPhoneNo(phoneNumber);
          tagManagerEvents("heUser", utmSource);
          // if (response.result.signIn) {
          //   setIsHidden(false);
          // } else {
            sendOTP(phoneNumber, true);
          // }
        } else {
          // wifi user
          tagManagerEvents("wifiUser", utmSource);
        }
      } else {
        setShowError(true);
        setErrorMsg("Something went wrong");
      }
    } catch (error) {
      setShowError(true);
      setErrorMsg("Something went wrong......!");
      console.log("handleGetFlow Error: ", error);
    }
  };

  const handlePhoneNoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (!value || (value.length <= 10 && value.match(/^3\d{0,9}$/))) {
      setShowError(false);
      setValidNum(true);
      setPhoneNo(e.target.value);
      setOtp({
        one: "",
        two: "",
        three: "",
        four: "",
      });
      if (value.length === 10) {
        sendOTP(e.target.value, false);
      }
    } else {
    }
  };

  // const checkOperator = async (phoneNumber: string) => {
  //   try {
  //     let response = await fetchGet(
  //       props.token,
  //       ApiNames.checkOperator + "?msisdn=" + phoneNumber
  //     );
  //     if (
  //       response.status === 0 &&
  //       response.network.toLowerCase() === "other network"
  //     ) {
  //       setValidNum(false);
  //       setShowError(true);
  //       setErrorMsg(
  //         "Service is only for Zong customers. Kindly enter a working Zong number."
  //       );
  //     } else {
  //       sendOTP(phoneNumber, false);
  //     }
  //   } catch (err) {
  //     setValidNum(false);
  //     setShowError(true);
  //     setErrorMsg("Something went wrong, Please try again.");
  //   }
  // };

  const sendOTP = async (phoneNo: string, autoFill: boolean) => {
    let _apiName = "";
    if (encodeMsisdn !== "") {
      _apiName = `${ApiNames.heSendOtp}?msisdn=${phoneNo}`;
    } else {
      _apiName = `${ApiNames.sendOtp}?msisdn=${phoneNo}`;
    }
    try {
      let response = await fetchGet(_apiName, props.token);
      if (response.status === 0 && response.result === "Success") {
        // setUserExists(response.exists);
        let _otp = response.otp.split("");
        let tempOTP = { ...otp };
        tempOTP.one = _otp[0];
        tempOTP.two = _otp[1];
        tempOTP.three = _otp[2];
        tempOTP.four = _otp[3];
        console.log(tempOTP);
        if (autoFill) {
          setOtp(tempOTP);
        }
        setOtpId(response.otpId);
      } else {
        setErrorMsg("Unable to send OTP, Please try again.");
      }
    } catch (err) {
      setErrorMsg("Something went wrong, Please try again.");
    }
  };

  const onSubscribePressed = () => {
    let checkOTP = otp.one + otp.two + otp.three + otp.four;
    let _qsPackageId = "1";
    if (qsPackageId === "1" || qsPackageId === "2" || qsPackageId === "3") {
      _qsPackageId = qsPackageId;
    }
    let data = {};
    let _apiName = "";
    if (heUser) {
      data = {
        msisdn: phoneNo,
        heId: heId,
        otp: checkOTP,
        subPackage: "sub1",
        package_id: _qsPackageId,
        utm_source: utmSource,
        nick: "GW-guestUser",
      };
      _apiName = ApiNames.heSubscribeWeb;
    } else {
      data = {
        msisdn: phoneNo,
        otpId: otpId,
        otp: checkOTP,
        subPackage: "sub1",
        package_id: _qsPackageId,
        utm_source: utmSource,
        nick: "GW-guestUser",
      };
      _apiName = ApiNames.subscribe;
    }
    subscribeUser(_apiName, data);
  };
  //Utm
  const checkUtm = async () => {
    try {
      let response = await fetchGet(ApiNames.utm, props.token);
      if (response.status === 0) {
        {
          response.response.map((e: any) => {
            if (utmSource === e.sf_utm) {
              console.log("ad_network", e.ad_network);
              adNetwork = e.ad_network;
            }
          });
        }
      }
    } catch (err) {
      console.log("UTM failed: " + err);
    }
  };

  // --------------- APIs ---------------
  const subscribeUser = async (endpoint: string, data: any) => {
    try {
      setShowLoader(true);
      let response = await fetchPost(endpoint, props.token, data);
      if (response.status === 0) {
        if (
          adNetwork.toLowerCase() === "tiktok" ||
          adNetwork.toLowerCase() === "tik tok"
        ) {
          if (qsPackageId === "1") {
            tagManagerEvents("zsubscribe", "ztiktok");
          } else if (qsPackageId === "2") {
            tagManagerEvents("zsubscribe", "ztiktokWeekly");
          } else if (qsPackageId === "3") {
            tagManagerEvents("zsubscribe", "ztiktokMonthly");
          } else {
            tagManagerEvents("subscribe", utmSource);
          }
        } else if (adNetwork.toLowerCase() === "google") {
          tagManagerEvents("zsubscribe", "zoptimus");
        } else {
          tagManagerEvents("subscribe", utmSource);
        }
        setIsHidden(false);
        setShowLoader(false);
        setPhoneNo("");
        setOtp({
          one: "",
          two: "",
          three: "",
          four: "",
        });
      } else {
        tagManagerEvents("subscribeFailed", utmSource);
        setShowLoader(false);
        setShowError(true);
        setErrorMsg("Unable to subscribe,Please try again.");
      }
    } catch (error) {
      tagManagerEvents("subscribeFailed", utmSource);
      setShowLoader(false);
      setShowError(true);
      setErrorMsg("Unable to subscribe,Please try again.");
    }
  };

  const handleChangeOTP = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let otpEntered = e.target.value;
    setShowError(false);

    if (!isNaN(otpEntered as any) && otpEntered !== " ") {
      setOtp({
        ...otp,
        [e.target.name]: otpEntered,
      });
      if (e.target.value.length === 1) {
        const nextInput = inputRef[index + 1];
        if (nextInput?.current) {
          nextInput.current.focus();
        }
      }
    } else {
      setShowError(true);
      setErrorMsg("Please enter a valid OTP");
    }
  };
  // backspace button
  const handleBackspace = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ): void => {
    if (e.keyCode === 8) {
      setShowError(false);
      const target = e.target as HTMLInputElement;
      if (target.value.length === 0 && index !== 0) {
        (inputRef[index - 1].current as HTMLInputElement).focus();
      }
    }
  };

  const onResendOtpPressed = async () => {
    tagManagerEvents("resendOTP", utmSource);
    setTimer(30);
    setResendClicked(true);
    setOtp({
      one: "",
      two: "",
      three: "",
      four: "",
    });
    sendOTP(phoneNo, false);
  };

  const handleOverLay = () => {
    setIsHidden(true);
  };

  return (
    <LandingPage
      showLoader={showLoader}
      errorMsg={errorMsg}
      showError={showError}
      onSubscribePressed={onSubscribePressed}
      phoneNo={phoneNo}
      otp={otp}
      handleChangeOTP={handleChangeOTP}
      handleBackspace={handleBackspace}
      onResendOtpPressed={onResendOtpPressed}
      inputRef={inputRef}
      handlePhoneNoChange={handlePhoneNoChange}
      seconds={seconds}
      validNum={validNum}
      isHidden={isHidden}
      handleOverLay={handleOverLay}
      package_id={qsPackageId}
    />
  );
};

export default LandingScreen;
