import React, { useState } from "react";
import MainImage from "../Assets/imgs/awareness-main-img.webp";
import "../styles/Awareness.css";
import yellowstar from "../Assets/imgs/yellowstar.webp";
import appstore from "../Assets/imgs/appstore.webp";
import google_play from "../Assets/imgs/google_play.webp";
type Props = {
  isHidden: boolean;
  handleOverLay: () => void
};
const Awareness = (props: Props) => {
  return (
    <>
      <div className="awareness-page">
        <div className="image">
          <img
            src={MainImage}
            width={340}
            height={598}
            alt="awareness main image"
          />
        </div>
        <div className={`cong-overlay ${props.isHidden ? "hidden" : ""}`}
        onClick={props.handleOverLay}></div>
        <div className={`cong-box ${props.isHidden ? "hidden" : ""}`}>
          <img src={yellowstar} alt="star" />
          <div className="mubarakhead">Mubarak Ho!</div>
          <div className="mubaraltext">
            Aap Kamyabi k sath Tamasha Family Ka hisa ban chukay hain,
            <br /> Abhi Tamasha app download karein!
          </div>
          <div className="tamashastore">
            <a href="https://app.tamashaweb.com/JHLA" target="_blank">
              <img src={appstore} alt="app store" />
            </a>
            <a href="https://app.tamashaweb.com/JHLA" target="_blank">
              <img src={google_play} alt="google play" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Awareness;
