import React, { useEffect } from "react";
import tagManagerEvents from "../utils/GoogleTagManager";
import { useNavigate } from "react-router-dom";

const JazzCash = () => {
  const navigate = useNavigate();
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  let jc = searchParams.get("jc") ? searchParams.get("jc") : "0";

  useEffect(() => {
    if (jc === "1") {
      tagManagerEvents("Subscription_landing", "econ");
      navigate("/landing", {
        state: {
          jc: 1,
        },
      });
    } else {
      navigate("/landing", {
        state: {
          jc: 0,
        },
      });
    }
  }, []);

  return <></>;
};

export default JazzCash;
