const ApiNames = {
  authenticateWeb: "authenticateWeb",
  GetFlow: "user/getFlow",
  awarenessFlow: "user/awarenessFlow",
  heSendOtp:"user/he_send_otp",
  sendOtp:"user/send_otp",
  subscribe: "user/subscribeWeb",
  heSubscribeWeb: "user/he_subscribeWeb",
  subscribeFastFlow: "user/ffSubscribe",
  unsubscribeWeb: "user/unsubscribeWeb",
  utm: "user/utmList",
};

export default ApiNames;
