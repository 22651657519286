import React, { useState, useEffect } from "react";
import "../styles/landingpage.css";
import mainImg from "../Assets/imgs/main-img.webp";
import mainImg2 from "../Assets/imgs/main-img-2.webp";
import yellowstar from "../Assets/imgs/yellowstar.webp";
import appstore from "../Assets/imgs/appstore.webp";
import google_play from "../Assets/imgs/google_play.webp";
import errorImg from "../Assets/imgs/errorImg.webp";

type Props = {
  otp: {
    one: string;
    two: string;
    three: string;
    four: string;
  };
  handleChangeOTP: (e: any, index: any) => void;
  handleBackspace: (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => void;
  onResendOtpPressed: () => void;
  inputRef: React.RefObject<HTMLInputElement>[];

  showLoader: boolean;
  errorMsg: string;
  showError: boolean;
  onSubscribePressed: () => void;
  phoneNo: string;
  handlePhoneNoChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  seconds: number;
  validNum: boolean;
  isHidden: boolean;
  handleOverLay: () => void;
  package_id: string | null;
  isPopupVisible: boolean;
  openPopup: () => void;
  closePopup: () => void;
};

export const LandingPage = (props: Props) => {
  const [seconds, setSeconds] = useState(30);
  const [isResendClicked, setResendClicked] = useState(false);
  useEffect(() => {
    if (isResendClicked) {
      setSeconds(30);
      setResendClicked(false);
    }

    const interval = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds === 0) {
          clearInterval(interval);
        }
        return prevSeconds > 0 ? prevSeconds - 1 : 0;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [isResendClicked]);

  const handleResendClick = () => {
    setResendClicked(true);
  };

  // error popup code

  // error popup code

  return (
    // <div className="lm-landingpage">
    <div className="">
      <div className="dm-section">
        <div className="main-content">
          {props.package_id === "2" || props.package_id === "3" ? (
            <img
              src={mainImg2}
              alt="sports"
              width={345}
              height={333}
              className="lp-main-image"
            />
          ) : (
            <img
              src={mainImg}
              alt="sports"
              width={345}
              height={333}
              className="lp-main-image"
            />
          )}
          <div className="lp-absolute-content">
            <div className="numberdiv">
              <p className="landingtext">Please enter your phone number</p>

              <div className="input-wraper">
                <span>+92</span>
                <input
                  type="number"
                  placeholder="3XX - XXXXXXX"
                  className="numberinput"
                  max={10}
                  value={props.phoneNo}
                  onChange={(e) => {
                    props.handlePhoneNoChange(e);
                  }}
                />
              </div>
            </div>
            {/* otp div */}
            {props.showError && <p className="error">{props.errorMsg}</p>}

            <div className="otpDiv">
              {props.phoneNo.length === 10 && props.validNum ? (
                <>
                  <p className="landingtext">
                    Please enter the OTP received via SMS.
                  </p>
                  <div className="otpinputWraper">
                    <input
                      type="tel"
                      name="one"
                      maxLength={1}
                      autoFocus
                      value={props.otp.one}
                      onChange={(e) => {
                        props.handleChangeOTP(e, 0);
                      }}
                      ref={props.inputRef[0]}
                      onKeyDown={(e) => {
                        props.handleBackspace(e, 0);
                      }}
                    />
                    <input
                      type="tel"
                      name="two"
                      maxLength={1}
                      value={props.otp.two}
                      onChange={(e) => {
                        props.handleChangeOTP(e, 1);
                      }}
                      ref={props.inputRef[1]}
                      onKeyDown={(e) => {
                        props.handleBackspace(e, 1);
                      }}
                    />
                    <input
                      type="tel"
                      name="three"
                      maxLength={1}
                      value={props.otp.three}
                      onChange={(e) => {
                        props.handleChangeOTP(e, 2);
                      }}
                      ref={props.inputRef[2]}
                      onKeyDown={(e) => {
                        props.handleBackspace(e, 2);
                      }}
                    />
                    <input
                      type="tel"
                      name="four"
                      maxLength={1}
                      value={props.otp.four}
                      onChange={(e) => {
                        props.handleChangeOTP(e, 3);
                      }}
                      ref={props.inputRef[3]}
                      onKeyDown={(e) => {
                        props.handleBackspace(e, 3);
                      }}
                    />
                  </div>

                  {/* <div className="invalidotp">Invalid OTP entered</div> */}

                  <div className="otpcounter">
                    <p>
                      {props.seconds === 0 ? (
                        <a
                          style={{ cursor: "pointer" }}
                          onClick={props.onResendOtpPressed}
                        >
                          Resend Otp
                        </a>
                      ) : null}
                      {props.seconds > 0 && (
                        <>
                          <>Resend 00:{props.seconds}</>
                          {props.seconds === 0 && (
                            <a
                              style={{ cursor: "pointer" }}
                              onClick={props.onResendOtpPressed}
                            >
                              Resend Otp
                            </a>
                          )}
                        </>
                      )}
                    </p>
                  </div>
                  {props.showLoader ? (
                    <div className="subscribe-btn">
                      <button>Loading...</button>
                    </div>
                  ) : (
                    <div className="subscribe-btn">
                      <button onClick={props.onSubscribePressed}>
                        Subscribe Now
                      </button>
                    </div>
                  )}
                </>
              ) : null}

              <div className="policytext">
                {props.package_id === "1" ? (
                  <p>Rs. 10 day for Prepaid & Rs. 120/mon for Postpaid</p>
                ) : (
                  <>
                    {props.package_id === "2" ? (
                      <p>Rs. 50/week for Prepaid & Rs. 120/mon for Postpaid</p>
                    ) : (
                      <>
                        {props.package_id === "3" ? (
                          <p>Rs.120/month for Prepaid & Postpaid</p>
                        ) : (
                          <p>
                            Rs. 10 day for Prepaid & Rs. 120/mon for Postpaid
                          </p>
                        )}
                      </>
                    )}
                  </>
                )}

                <div className="checkflex">
                  <input type="checkbox" checked />
                  <p>
                    By signing in, you agree to the{" "}
                    <a href="/terms-of-use" target="_blank">
                      {" "}
                      Terms of Use
                    </a>{" "}
                    and
                    <a href="/privacy-policy" target="_blank">
                      {" "}
                      Privacy Policy.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`cong-overlay ${props.isHidden ? "hidden" : ""}`}
            onClick={props.handleOverLay}
          ></div>
          <div className={`cong-box ${props.isHidden ? "hidden" : ""}`}>
            <img src={yellowstar} alt="star" />
            <div className="mubarakhead">Mubarak Ho!</div>
            <div className="mubaraltext">
              Aap Kamyabi k sath Tamasha Family Ka hisa ban chukay hain,
              <br /> Abhi Tamasha app download karein!
            </div>
            <div className="tamashastore">
              <a href="https://app.tamashaweb.com/JHLA" target="_blank">
                <img src={appstore} alt="app store" />
              </a>
              <a href="https://app.tamashaweb.com/JHLA" target="_blank">
                <img src={google_play} alt="google play" />
              </a>
            </div>
          </div>

          {props.isPopupVisible && (
            <div className="errorOverlay" onClick={props.closePopup}></div>
          )}
          {props.isPopupVisible && (
            <div className="errorpopup">
              <div>
                <img src={errorImg} alt="error" width={40} height={40} />
              </div>
              <p className="oops">Oops</p>
              <div className="subscriptiontext">
                Subscription kaamyaab nahi hui.
              </div>
              <button className="tryagain" onClick={props.closePopup}>
                Try Again
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default LandingPage;
