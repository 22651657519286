// export const ImgUrl = process.env.REACT_APP_Image_URL;
// console.log(ImgUrl);
// export const ImgUrl = "https://jgw.ecnstg.com";

const convertUrl = () => {
  let url = process.env.REACT_APP_BASE_URL_Zong;
  console.log(url)
  if (window.location.protocol === "http:") {
    url = url.replace("https", "http");
    return url;
  } else {
    return url;
  }
};
export const fetchGet = async (apiName, token) => {
  let url = convertUrl();
  let response = await fetch(`${url}${apiName}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  let jsonResponse = await response.json();
  return jsonResponse;
};

export const fetchPost = async (apiName, token, data) => {
  let url = convertUrl();
  console.log(`${url}${apiName}`,data)
  let response = await fetch(`${url}${apiName}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token ? `Bearer ${token}` : null,
    },
    body: JSON.stringify(data),
  });

  let jsonResponse = await response.json();

  return jsonResponse;
};

export const _fetchPost = async (apiName, token, data) => {
  let url = convertUrl();
  console.log(`${url}${apiName}`,data)

};