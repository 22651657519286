import React, { useEffect, useState } from "react";
import AwarenessScreen from "./AwarenessScreen";
import LandingScreen from "./LandingScreen";

interface IProps {
  token: string | null;
}

const SubscriptionScreen = (props: IProps) => {
  const [pageState, setPageState] = useState<number>(0);
  useEffect(() => {
    console.log("useEffect");
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    const esf: string | null = searchParams.get("esf");
    const utmSource: string | null = searchParams.get("utm_source");
    if (esf === "0" && utmSource === "xsGTWNRg8T") {
      setPageState(1);
    } else {
      setPageState(2);
    }
  }, []);

  return (
    <div>
      {pageState === 0 ? (
        <div></div>
      ) : pageState === 1 ? (
        <AwarenessScreen token={props.token} />
      ) : (
        <LandingScreen token={props.token} />
      )}
    </div>
  );
};

export default SubscriptionScreen;
