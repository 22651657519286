// import React from "react";
import React from "react";
import "../styles/landingpage.css";
import mainImg from "../Assets/imgs/main-img.webp";
import jazzcashimg from "../Assets/imgs/jazzcash_logo.webp";
type Props = {
  phoneNo: string;
  handlePhoneNoChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error: string;
  handleNavigation: () => void;
  isCheckRadioBtn: boolean;
  setIsCheckRadioBtn: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<string>>;
};

export const LandingJazzCash = (props: Props) => {
  return (
    <div className="dm-section jazzcashlanding">
      <div className="main-content">
        <img
          src={mainImg}
          alt="sports"
          width={345}
          height={333}
          className="lp-main-image"
        />
        <div className="lp-absolute-content">
          <div className="numberdiv">
            <p className="landingtext">Please enter your phone number</p>
            <form>
              <div className="input-wraper">
                <span>
                  <img
                    src={jazzcashimg}
                    width={60}
                    height={28}
                    alt="jazzcash"
                  />
                </span>
                <input
                  type="number"
                  placeholder="3XX - XXXXXXX"
                  className="numberinput"
                  max={10}
                  value={props.phoneNo}
                  onChange={(e) => {
                    props.handlePhoneNoChange(e);
                  }}
                />
              </div>

              {props.error.length > 0 && <p className="error">{props.error}</p>}

              <div className="continue-jazzcash">
                <span
                  className="blue-btn"
                  onClick={() => {
                    props.handleNavigation();
                  }}
                >
                  Continue
                </span>
              </div>
            </form>
          </div>

          <div className="otpDiv">
            <div className="policytext">
              <div className="checkflex">
                <input
                  type="checkbox"
                  checked={props.isCheckRadioBtn}
                  onChange={() => {
                    props.setError("");
                    props.setIsCheckRadioBtn(!props.isCheckRadioBtn);
                  }}
                />
                <p>
                  By signing in, you agree to the{" "}
                  <a href="/terms-of-use" target="_blank">
                    Terms of Use
                  </a>{" "}
                  and{" "}
                  <a href="/privacy-policy" target="_blank">
                    Privacy Policy.
                  </a>
                </p>
              </div>

              <div className="continue-text">
                * Continue click krne se aap ke account se Rs. 1 kat jayega. Yeh
                offer 24 hours ke liye valid hai jis ke baad automatically Rs.
                120/month inc. tax deduct honge.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};

export default LandingJazzCash;
